<template>
  <el-dialog
    :title="'充值'"
    :close-on-click-modal="false"
    :visible.sync="visible"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="公司" prop="companyId">
        <el-select v-model="dataForm.companyId" filterable :disabled="disabled">
          <el-option
            v-for="(item, index) in companyList"
            :key="index"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="充值金额" prop="changedMoney">
        <el-input
          v-model="dataForm.changedMoney"
          :disabled="disabled"
          placeholder="充值金额"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getCompanyList } from '@/utils/options.js';
import { keys, pick } from 'lodash';
export default {
  data() {
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        companyId: '',
        companyName: '',
        type: 1,
        changedTime: '',
        changedMoney: '',
        auditStatus: '',
        approveName: '',
        reviewTime: '',
        remarks: '',
      },
      companyList: [],
      dataRule: {
        changedMoney: [
          { required: true, message: '充值金额不能为空', trigger: 'blur' },
        ],
        companyId: [
          { required: true, message: '公司不能为空', trigger: 'blur' },
        ],
      },
    };
  },

  created() {
    this.getCompanyList();
  },

  methods: {
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/tc/companyaccountdetail/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.companyaccountdetail, keys(this.dataForm));
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/tc/companyaccountdetail/${
              !this.dataForm.id ? 'save' : 'update'
            }`,
            method: 'post',
            data: {
              id: this.dataForm.id || undefined,
              companyId: this.dataForm.companyId,
              type: this.dataForm.type,
              changedTime: this.dataForm.changedTime,
              changedMoney: this.dataForm.changedMoney,
              remarks: this.dataForm.remarks,
            },
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },

    //获得公司下拉框
    getCompanyList() {
      getCompanyList().then(({ data }) => {
        if (data && data.code === 0) {
          this.companyList = data.list;
        }
      });
    },
  },
};
</script>
